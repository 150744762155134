import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import { StudentAssignmentStatus } from "../../../data-types/Types";
import DisplayFCQ from "../components/DisplayFCQ";
import { useData } from "../services/DataProvider";
// const fishersExactTest = require('fishers-exact-test');

const groupBy = function(xs: any[], key:string) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const improvement = (data: any) => {
  let rate1 = data[0][1] / Math.max(1, data[0][0] + data[0][1]);
  let rate2 = data[1][1] / Math.max(1, data[1][0] + data[1][1]);
  return rate2 - rate1;
};

// SEEMS TO TAKE TOO LONG!
// const pValue = (data: any)=> {
//   return fishersExactTest(data[0][0], data[0][1], data[1][0], data[1][1]).twoTailedPValue;
// }
// .sort((a, b)=>{return pValue(questionToBeforeAfter[a]) > pValue(questionToBeforeAfter[b])? -1 : 1})

const Analytics:React.FC = ()=>{
  const {adminData, queryAdminData, standards, questions} = useData();

  let statusGroups = {} as any;
  let totalTested = 0;
  let questionToBeforeAfter = {} as any;

  if (!adminData.allStatuses) {
    queryAdminData(); // TODO: This seems to cause an error related to updating a variable while rendering another component.
  } else {
    // Group by students / enrollments
    statusGroups = groupBy(adminData.allStatuses, "enrollmentID");
    for (const student in statusGroups) {
      // TODO: Find all copies of each question answered.
      const statuses = (statusGroups?.[student]) as StudentAssignmentStatus[];
      if (statuses.length < 2) {
        continue;
      } //TODO: Fix to account for taking multiple assignments, but not corresponding pretests and post-tests
      totalTested += 1;
      let allOutcomes = [] as {qid:string; timestamp:Date; correctness:boolean}[];
      // TODO: Compute based on inferred correctnesses...
      for (const status of statuses) {
        for (const qid in status.timestamps) {
          allOutcomes.push({qid: qid, timestamp: status.timestamps?.[qid], correctness: status.correctness?.[qid]})
        }
      }
      const groupedOutcomes = groupBy(allOutcomes, "qid");
      for (const qid in groupedOutcomes) {
        let outcomes = groupedOutcomes?.[qid];
        if (outcomes.length < 2) {
          continue;
        }
        outcomes.sort((a:any, b:any)=>a.timestamp < b.timestamp? 1 : -1);
        console.log(JSON.stringify(outcomes));
        if (!(qid in questionToBeforeAfter)) {
          questionToBeforeAfter[qid] = [[0, 0], [0, 0]]; 
        }
        let firstOutcome = outcomes[0].correctness? 1 : 0;
        let lastOutcome = outcomes[outcomes.length - 1].correctness? 1 : 0;
        questionToBeforeAfter[qid][0][firstOutcome] += 1;
        questionToBeforeAfter[qid][1][lastOutcome] += 1;
      }
    }
  }

  return <IonCard>
    <IonCardHeader><IonCardTitle>Analytics</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <p>Unique Students: {totalTested}</p>
      {Object.keys(questionToBeforeAfter).sort((a, b)=>{return improvement(questionToBeforeAfter[a]) < improvement(questionToBeforeAfter[b])? 1 : -1}).map((qid:any)=>{
        return <div key={qid} style={{border:"1px solid black", borderRadius:".5em", padding:".25em", margin:".25em"}}>
        
          <p>Counts: {JSON.stringify(questionToBeforeAfter[qid])}</p>
          <p>Improvement: {improvement(questionToBeforeAfter[qid])}</p>

          <DisplayFCQ question={questions.filter(elem => elem.id===qid)?.[0]} />
        </div>

      })}
    </IonCardContent>
  </IonCard>
}

export default Analytics;